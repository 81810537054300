import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6642d4b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inner content" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 2,
  class: "home-header"
}
const _hoisted_5 = { class: "home-btn-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_FlatButton = _resolveComponent("FlatButton")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass({ 'bg-white': _ctx.isWhite, 'home-header': _ctx.homePage })
  }, [
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.isWhite)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: require('@/assets/logo/digitalsign-logo.svg'),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.backToHome && _ctx.backToHome(...args))),
            class: "logo"
          }, null, 8, _hoisted_2))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: require('@/assets/logo/digitalsign-logo-black.svg'),
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.backToHome && _ctx.backToHome(...args))),
            class: "logo"
          }, null, 8, _hoisted_3)),
      (_ctx.homePage)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("p", _hoisted_5, [
              _createVNode(_component_router_link, {
                class: _normalizeClass({ 'text-primary': _ctx.isWhite }),
                to: { name: '' }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("header.downloadMaterial")), 1)
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_router_link, {
                class: _normalizeClass({ 'text-primary': _ctx.isWhite }),
                to: { name: '' }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("header.contact")), 1)
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_router_link, {
                class: _normalizeClass({ 'text-primary': _ctx.isWhite }),
                to: { name: 'Login' }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("header.login")), 1)
                ]),
                _: 1
              }, 8, ["class"])
            ]),
            _createVNode(_component_FlatButton, {
              class: "header-btn",
              onOnClick: _ctx.goToSignUp,
              text: _ctx.$t('header.signUp')
            }, null, 8, ["onOnClick", "text"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}