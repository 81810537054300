<template>
  <div class="wrapper">
    <div>
      <Header :login-page="true" />
      <div class="login">
        <router-view />
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/organisms/HomeHeader.vue";
import Footer from "@/components/organisms/Footer.vue";

export default {
  name: "Login",
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .login {
    letter-spacing: 0.05em;
    background: linear-gradient(-15deg, $secondary4 50%, $primary1 50%);

    @include mq(l) {
      padding: 0 10px;
    }
  }
}
</style>
