
import { defineComponent } from "vue";
import { useStore } from "vuex";
import FlatButton from "@/components/atomics/FlatButton.vue";
// import TextButton from "@/components/atomics/TextButton.vue";

export default defineComponent({
  name: "HomeHeader",
  components: {
    FlatButton,
    // TextButton,
  },
  props: {
    isAfterLogin: { type: Boolean, default: false, required: false },
    loginPage: { type: Boolean, default: false, required: false },
    homePage: { type: Boolean, default: false, required: false },
    registerPage: { type: Boolean, default: false, required: false },
    isWhite: { type: Boolean, default: false },
  },
  methods: {
    backToHome() {
      if (this.$route.path != "/") {
        this.$router.push({ name: "Home" });
      } else {
        location.reload();
      }
    },

    openContactPopup() {
      useStore().dispatch("popup/setPopup", "PopupContact");
    },
    goToSignUp(): void {
      this.$router.push({ name: "SignUp" });
    },
  },
});
/**
 * @vuese
 * @group Atomic/Organisms
 * footter
 */
